import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { LabelText } from 'app/components/Common/LabelText'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  description?: string
  images?: ImageProps[]
  label?: string
  title?: string
}

export const VisualServices = memo(function VisualServices({
  cta,
  description,
  images,
  label,
  title,
}: Props) {
  return (
    <Container>
      {description || title ? (
        <Wrap>
          {label ? (
            <FadeInUp>
              <LabelText label={label} />
            </FadeInUp>
          ) : null}
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </Wrap>
      ) : null}
      {images && images[0] ? (
        <MainImage>
          <LazyLoadComponent>
            <Image {...images[0]} />
            {cta ? <CTA {...cta} /> : null}
          </LazyLoadComponent>
        </MainImage>
      ) : null}
      {images && images[1] ? (
        <BackgroundImage>
          <LazyLoadComponent>
            <Image {...images[1]} />
          </LazyLoadComponent>
        </BackgroundImage>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin-top: 14.375rem;
  padding-bottom: 7.5rem;
  position: relative;
  text-align: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    margin-top: 6.25rem;
    padding: 1.875rem;
  }
  @media (max-width: 767px) {
    margin-top: 5.625rem;
  }
`

const Wrap = styled.div`
  max-width: 45rem;
  margin: auto;
  padding: 0 2.083vw;

  @media (max-width: 1023px) {
    padding: 0;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-size: 3.75rem;
  line-height: 4.75rem;
  margin-bottom: 1rem;

  @media (max-width: 1023px) {
    font-size: 2.25rem;
    line-height: 3rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 400;
  line-height: 2rem;

  @media (max-width: 1023px) {
    line-height: 1.75rem;
  }
`

const MainImage = styled.div`
  width: calc(100% - 27.778vw);
  margin: 4.375rem auto 3.125rem;
  padding-bottom: 40%;
  position: relative;
  z-index: 3;
  &:before {
    content: '';
    width: 100%;
    height: 50%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.2)}
    );
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 1023px) {
    width: calc(100% - 1.875rem);
    margin: 3.125rem auto 1.875rem;
  }

  @media (max-width: 767px) {
    padding-bottom: 60%;
    width: 100%;
    margin: 3.75rem auto 1.875rem;
  }

  @media (max-width: 479px) {
    padding-bottom: 80%;
  }

  @media (max-width: 374px) {
    padding-bottom: 100%;
  }
`

const CTA = styled(Button)`
  position: absolute;
  bottom: 6.25rem;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);

  @media (max-width: 1023px) {
    bottom: 1.875rem;
  }
  @media (max-width: 767px) {
    min-width: auto;
  }
`

const BackgroundImage = styled.div`
  width: 100%;
  padding-bottom: 42%;
  position: absolute;
  bottom: 0;
  left: 0;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.3;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 767px) {
    padding-bottom: 50%;
  }
`
