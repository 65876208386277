import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Section } from 'app/components/Common/Section'
import { Facebook, Instagram } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  facebookURL?: string
  images: ImageProps[]
  instagramURL?: string
  languageCode: string
}

export const SocialWall = memo(function SocialWall({
  facebookURL,
  images,
  instagramURL,
  languageCode,
}: Props) {
  if (images.length < 1) {
    return null
  }

  return (
    <Container>
      <Section label="follow-us" languageCode={languageCode} />
      <Hashtag>
        <div>
          {facebookURL ? (
            <Anchor
              aria-label="Facebook"
              href={facebookURL}
              rel="noreferrer"
              target="_blank"
            >
              <Facebook />
            </Anchor>
          ) : null}
          {instagramURL ? (
            <Anchor
              aria-label="Instagram"
              href={instagramURL}
              rel="noreferrer"
              target="_blank"
            >
              <Instagram />
            </Anchor>
          ) : null}
        </div>
        #hotelparmaeoriente
      </Hashtag>
      <Images dial={2} row wrap>
        {images.slice(0, 5).map((item, index) => (
          <LazyLoadImage key={index} effect="blur" {...item} />
        ))}
      </Images>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-top: 19.125rem;
  padding: 3.75rem 6.667vw 5.625rem;
  position: relative;
  text-align: center;

  @media (max-width: 1023px) {
    margin-top: 12.5rem;
    padding: 2.5rem 0 1.875rem;
  }
  @media (max-width: 767px) {
    margin-top: 6.25rem;
  }
`

const Anchor = styled.a`
  display: flex;
  align-items: center;
  height: 1.25rem;
  margin-right: 1.875rem;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.variants.primaryLight};
    }
  }

  svg {
    display: inline-block;
    height: 1.25rem;
    margin: 0 auto;
    fill: ${({ theme }) => theme.colors.variants.neutralDark3};
    transition: 0.2s ease-in-out;
  }

  @media (max-width: 1023px) {
    margin: 0 1.25rem !important;
  }
`

const Hashtag = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  margin: 0 auto;
  letter-spacing: 0.2rem;
  line-height: 2rem;
  text-transform: uppercase;
  max-width: 15.625rem;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.25rem;
  }
`

const Images = styled(FlexBox)`
  margin: 3.125rem -2.3438rem 0;

  .lazy-load-image-background {
    width: calc(20% - 4.6875rem) !important;
    height: auto !important;
    margin: 0 2.3438rem;
    padding-bottom: calc(20% - 4.6875rem);
    position: relative;
    top: auto;
    left: auto;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    margin: 2.25rem -0.9375rem 0;

    .lazy-load-image-background {
      width: calc(16.666% - 1.875rem) !important;
      margin: 0 0.7813rem;
      padding-bottom: calc(16.666% - 1.875rem);
    }
  }

  @media (max-width: 767px) {
    margin: 2.25rem -0.125rem 0;

    .lazy-load-image-background {
      width: calc(16.666% - 0.25rem) !important;
      margin: 0 0.375rem;
      padding-bottom: calc(16.666% - 0.25rem);
    }
  }
`
