import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  className?: string
  distance?: string
  image?: ImageProps
  title?: string
}

export const Card = memo(function Card({
  className,
  distance,
  image,
  title,
}: Props) {
  return (
    <Container className={className}>
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}
      {distance || title ? (
        <Wrapper className="service-wrap">
          {distance ? <Distance>{distance}</Distance> : null}
          {title ? <Title>{title}</Title> : null}
        </Wrapper>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  padding-bottom: calc(50% - 9.861vw);
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  position: relative;
  text-transform: uppercase;

  &:after {
    content: '';
    width: 100%;
    backface-visibility: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    height: 50%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.75)}
    );
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1);
    transition: all 0.3s ease-in-out !important;
  }

  @media (max-width: 767px) {
    padding-bottom: 60%;
  }

  @media (max-width: 574px) {
    padding-bottom: 75%;
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 4rem;
  right: 3.375rem;
  left: 3.375rem;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  z-index: 3;
  text-align: left;

  @media (max-width: 767px) {
    bottom: 1.875rem;
    right: 1.875rem;
    left: 1.875rem;
  }
`

const Distance = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2rem;
  margin-bottom: 1.25rem;

  @media (max-width: 767px) {
    font-size: 1.625rem;
    line-height: 1.75rem;
    margin-bottom: 1rem;
  }
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  letter-spacing: 0.2rem;
  line-height: 1.25rem;
  font-weight: 700;

  @media (max-width: 767px) {
    line-height: 1rem;
  }
`
