import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { LabelText } from 'app/components/Common/LabelText'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Props as SlideProps, Slide } from './Slide'

const AUTOPLAY_TIMER = 5000

export interface Props {
  cta?: ButtonProps
  label?: string
  slides: SlideProps[]
  title?: string
}

export const OffersSlider = memo(function OffersSlider({
  cta,
  label,
  slides,
  title,
}: Props) {
  if (slides.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [opacities, setOpacities] = React.useState<number[]>([])
  const timer = useRef<any>()

  const [sliderRef, instanceRef] = useKeenSlider({
    drag: false,
    loop: slides.length > 1 ? true : false,
    slides: slides.length,
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      const newOpacities = s.track.details.slides.map((slide) => slide.portion)
      slides.length > 1 ? setOpacities(newOpacities) : null
    },
    breakpoints: {
      '(max-width: 767px)': {
        drag: true,
      },
    },
  })

  useEffect(() => {
    timer.current = setInterval(() => {
      if (instanceRef) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container dial={1} row stretch tag="section" wrap>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <LeftSide dial={1}>
          {label ? (
            <FadeInUp>
              <LabelText label={label} />
            </FadeInUp>
          ) : null}
          {title ? (
            <FadeInUp>
              <Title dangerouslySetInnerHTML={{ __html: title }} />
            </FadeInUp>
          ) : null}
          {cta ? (
            <Media greaterThanOrEqual="ipadHorizontal">
              <FadeInUp>
                <Button {...cta} />
              </FadeInUp>
            </Media>
          ) : null}
        </LeftSide>

        <RightSide>
          <Slider ref={sliderRef}>
            {slides.map((item, index) => (
              <Slide
                key={index}
                style={{
                  opacity: opacities[index],
                  zIndex: opacities[index],
                }}
                {...item}
              />
            ))}
          </Slider>

          {slides.length > 1 ? (
            <Arrows row>
              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
              />
              <Arrow
                direction="R"
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
              />
            </Arrows>
          ) : null}
          {cta ? (
            <Media lessThan="ipadHorizontal">
              <FadeInUp>
                <Button className="cta-bottom" {...cta} />
              </FadeInUp>
            </Media>
          ) : null}
        </RightSide>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled(FlexBox)`
  padding: 0 14.028vw;
  margin-top: 12.5rem;

  @media (max-width: 1199px) {
    padding: 0 6.944vw;
  }

  @media (max-width: 1023px) {
    padding: 0 1.875rem;
    margin-top: 6.25rem;
  }
`

const LeftSide = styled(FlexBox)`
  width: 50%;

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.75rem;
  line-height: 4.75rem;
  margin-bottom: 3.75rem;

  b {
    color: ${({ theme }) => theme.colors.variants.primaryLight};
    font-style: italic;
    font-weight: 500;
  }

  @media (max-width: 1023px) {
    font-size: 2.25rem;
    line-height: 3rem;
    margin-bottom: 1.875rem;
  }
`

const RightSide = styled.div`
  width: 50%;
  position: relative;

  @media (max-width: 1023px) {
    width: 100%;
  }

  .cta-bottom {
    margin-top: 1.875rem;
  }
`

const Slider = styled.div`
  display: flex;
  height: calc(50vw - 14.028vw);
  position: relative;

  @media (max-width: 1199px) {
    height: calc(50vw - 6.944vw);
  }

  @media (max-width: 1023px) {
    height: calc(60vw - 1.875rem);
    margin-top: 1.875rem;
  }
  @media (max-width: 767px) {
    height: calc(75vw - 1.875rem);
  }

  @media (max-width: 574px) {
    height: 50vh;
  }
`

const Arrows = styled(FlexBox)`
  margin-top: 1.875rem;
  justify-content: start;
  position: relative;
  right: auto;
  left: 0;
  z-index: 2;

  > div {
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    border-radius: 50%;

    &:nth-of-type(1) {
      margin-right: 1.25rem;
    }

    svg {
      stroke: ${({ theme }) => theme.colors.variants.neutralDark3};
      stroke-width: 1;
      height: 1.125rem;
    }
  }
`
