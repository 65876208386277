import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { LabelText } from 'app/components/Common/LabelText'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  services: ServiceProps[]
  label?: string
  title?: string
}

export const ServicesPreview = memo(function ServicesPreview({
  services,
  label,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container>
      {label ? (
        <FadeInUp>
          <LabelText label={label} />
        </FadeInUp>
      ) : null}
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      <Services row wrap>
        {services.slice(0, 2).map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </Services>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark4};
  padding: 8.125rem 0 0;
  text-align: center;

  @media (max-width: 1023px) {
    padding: 5.625rem 0 0;
  }

  .label-text {
    color: ${({ theme }) => theme.colors.variants.neutralLight4};

    @media (max-width: 1023px) {
      padding: 0 1.875rem;
    }
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 400;
  line-height: 4.25rem;

  @media (max-width: 1023px) {
    font-size: 2.25rem;
    line-height: 3rem;
    padding: 0 1.875rem;
  }
`

const Services = styled(FlexBox)`
  margin-top: 8.125rem;

  @media (max-width: 1023px) {
    margin-top: 3.125rem;
  }
`
