import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  url?: string
  image?: ImageProps
  title?: string
}

export const Service = memo(function Service({ url, image, title }: Props) {
  return (
    <Container to={url || '#'}>
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}
      {title ? (
        <Title className="service-title" dial={4} row>
          <TitleLabel>{title}</TitleLabel>
        </Title>
      ) : null}
    </Container>
  )
})

const Container = styled(Link)`
  width: 50%;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  position: relative;
  text-align: left;
  padding-bottom: 44%;
  overflow: hidden;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.3;
    z-index: 2;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
    .service-title {
      &:before {
        width: 3.75rem;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1);
    transition: all 0.3s ease-in-out !important;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-bottom: 75%;
  }
`
const Title = styled(FlexBox)`
  min-height: 3.375rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.375rem;
  position: absolute;
  bottom: 5.556vw;
  right: 1.875rem;
  left: 6.944vw;
  transition: 0.3s ease-in-out;
  z-index: 2;
  &:before {
    content: '';
    width: 1.875rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight};
    margin-right: 1.389vw;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 767px) {
    font-size: 1.625rem;
    line-height: 1.875rem;
    bottom: 1.875rem;
    left: 1.875rem;
  }
`

const TitleLabel = styled.div``
