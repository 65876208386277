import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { LabelText } from 'app/components/Common/LabelText'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Card, Props as CardProps } from './Card'

const AUTOPLAY_TIMER = 5000

export interface Props {
  cta?: ButtonProps
  description?: string
  cards: CardProps[]
  label?: string
  title?: string
}

export const ActivityPreview = memo(function ActivityPreview({
  cta,
  description,
  cards,
  label,
  title,
}: Props) {
  if (cards.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const timer = useRef<any>()

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: cards.length > 3 ? true : false,
    slides: {
      perView: 2.3625,
      spacing: 80,
    },
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    breakpoints: {
      '(max-width: 1123px)': {
        slides: {
          perView: 2.3,
          spacing: 40,
        },
      },
      '(max-width: 767px)': {
        slides: {
          perView: 1.3,
          spacing: 30,
        },
      },
      '(max-width: 574px)': {
        slides: {
          perView: 1.4,
          spacing: 20,
        },
      },
    },
  })

  useEffect(() => {
    timer.current = setInterval(() => {
      if (instanceRef) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      <Head dial={5} wrap row>
        <LeftSide>
          {label ? (
            <FadeInUp>
              <LabelText variant="left" label={label} />
            </FadeInUp>
          ) : null}
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
        </LeftSide>

        <RightSide>
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </RightSide>
      </Head>
      <Wrapper>
        <Slider ref={sliderRef}>
          {cards.map((item, index) => (
            <Card className="keen-slider__slide" key={index} {...item} />
          ))}
        </Slider>
      </Wrapper>

      <Footer dial={5} row space="between">
        {cards.length > 3 ? (
          <Arrows row>
            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              variant="small"
            />
            <Arrow
              direction="R"
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              variant="small"
            />
          </Arrows>
        ) : null}

        {cta ? (
          <FadeInUp>
            <CTA {...cta} />
          </FadeInUp>
        ) : null}
      </Footer>
    </Container>
  )
})

const Container = styled.section`
  margin-top: 12.5rem;
  overflow: hidden;
  position: relative;
  text-align: center;
  padding-bottom: 9.375rem;

  @media (max-width: 1023px) {
    margin-top: 6.25rem;
    padding-bottom: 4.6875rem;
  }
`
const Head = styled(FlexBox)`
  padding: 0 14.028vw;
  margin-bottom: 3.125rem;
  text-align: left;

  @media (max-width: 1199px) {
    padding: 0 6.944vw;
  }

  @media (max-width: 1023px) {
    padding: 0 1.875rem;
  }
`

const LeftSide = styled.div`
  width: 50%;
  padding-right: 2.5rem;

  @media (max-width: 1023px) {
    width: 100%;
    padding-right: 0;
  }
`
const RightSide = styled.div`
  width: 50%;
  padding-left: 2.5rem;

  @media (max-width: 1023px) {
    width: 100%;
    padding-left: 0;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.75rem;
  line-height: 4.75rem;

  @media (max-width: 1023px) {
    font-size: 2.25rem;
    line-height: 3rem;
  }
`

const Wrapper = styled.div`
  padding-left: 14.028vw;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
  }

  @media (max-width: 1199px) {
    padding-left: 6.944vw;
  }

  @media (max-width: 1023px) {
    padding-left: 1.875rem;
  }
`

const Footer = styled(FlexBox)`
  padding: 0 14.028vw;
  margin-top: 1.875rem;

  @media (max-width: 1199px) {
    padding: 0 6.944vw;
  }

  @media (max-width: 1023px) {
    padding: 0 1.875rem;
  }
`

const Slider = styled.div`
  display: flex;
  overflow: hidden;
  &[data-keen-slider-moves] {
    a {
      pointer-events: none;
    }
  }
`

const Arrows = styled(FlexBox)`
  > div {
    width: 5.125rem;
    height: 5.125rem;
    border-radius: 50%;

    &:nth-last-of-type(1) {
      margin-left: 1.5rem;
    }

    svg {
      stroke: ${({ theme }) => theme.colors.variants.neutralDark3};
      stroke-width: 1;
      height: 1.125rem;
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Description = styled.div`
  max-width: 38.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin: 4.75rem auto 0;

  @media (max-width: 1023px) {
    max-width: none;
    line-height: 1.75rem;
    margin-top: 1.5rem;
  }
`

const CTA = styled(Button)``
